@font-face {
  font-family: 'MTSSans';
  font-weight: 400;
  font-style: normal;
  src: url('assets/fonts/MTSSans-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'MTSSans';
  font-weight: 500;
  font-style: normal;
  src: url('assets/fonts/MTSSans-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'MTSSans';
  font-weight: 600;
  font-style: normal;
  src: url('assets/fonts/MTSSans-Bold.otf') format('opentype');
}

.leaflet-image-layer {
  z-index: 0 !important;
}
