.input {
	font-size: 17px;
	line-height: 24px;
	width: 100%;
	border: none;
	outline: 0;
	outline-offset: 0;
	background-color: transparent;
}

.input:active, :hover, :focus {
	outline: 0;
	outline-offset: 0;
}
.input::placeholder {
	color: #969fa8;
}
.input::-webkit-outer-spin-button, .input::-webkit-inner-spin-button {
	appearance: none;
	margin: 0;
	border: none;
}
.input[type="number"] {
	appearance: textfield;
	border: none;
}
.wrapper {
	background-color: #fff;
	position: relative;
	width: 100%;
	display: inline-block;
	height: 44px;
	border-radius: 8px;
	border: 2px #bcc3d080 solid;
	padding: 8px 12px;
}
.wrapper:focus-within {
	border-color: #007cff;
}
.wrapper.disabled {
	background-color: #f2f3f7;
	color: #626c77;
}
.wrapper.size-s {
	height: 32px;
	padding: 4px 12px;
	border-radius: 6px;
}
.wrapper.size-s .input {
	font-size: 14px;
	line-height: 20px;
}
.wrapper.size-l {
	height: 52px;
	padding: 12px;
}
.wrapperError {
	border-color: #eb4a13;
}
.label {
	width: 100%;
	display: inline-block;
	text-align: left;
	margin-bottom: 4px;
	font-size: 14px;
	line-height: 20px;
	color: #626c77;
}
.description {
	color: #626c77;
	font-size: 14px;
	line-height: 20px;
	position: absolute;
	bottom: -22px;
	left: -2px;
}
.error {
	position: absolute;
	bottom: -22px;
	left: -2px;
	color: #eb4a13;
	font-size: 12px;
	line-height: 16px;
}
.icons {
	position: absolute;
	display: flex;
	top: 50%;
	transform: translateY(-50%);
	right: 8px;
}
