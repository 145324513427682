.popperCustomClass {
	z-index: 2;
}
.datePickerWrapper :global .react-datepicker__portal {
	background-color: rgba(29, 32, 35, 0.4);
	backdrop-filter: blur(8px);
	min-width: 320px;
}
.datePicker {
	font-family: inherit;
	background-color: #fff;
	font-size: 17px;
	line-height: 24px;
	box-shadow: 0 4px 24px rgba(0, 0, 0, 0.12), 0 12px 20px rgba(0, 0, 0, 0.14);
	border-radius: 16px;
	overflow: hidden;
	border: none;
	user-select: none;
	padding: 76px 24px 92px 24px;
	width: 312px;
	color: #1d2023;
}
@media (min-width: 960px) {
	.datePicker {
		padding: 14px 12px 12px 12px;
		width: unset;
		min-width: 272px;
	}
}
.datePicker .heading {
	position: absolute;
	width: 100%;
	height: 52px;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	box-shadow: 0 0 16px rgba(0, 0, 0, 0.08), 0 4px 16px rgba(0, 0, 0, 0.08);
}
@media (min-width: 960px) {
	.datePicker .heading {
		display: none;
	}
}
.datePicker .heading .text {
	margin: 0;
	font-weight: 500;
}
.datePicker .heading .close {
	cursor: pointer;
	position: absolute;
	top: 10px;
	right: 10px;
}
.datePicker > div > div:first-of-type {
	background-color: #fff;
	border: none;
}
.datePicker :global .react-datepicker__month-container {
	margin: 0;
	width: 100%;
	float: none;
}
.datePicker :global .react-datepicker__month {
	margin: 0;
}
.datePicker :global .react-datepicker__year-wrapper {
	display: grid;
	grid-template-rows: repeat(4, 1fr);
	grid-template-columns: repeat(3, 1fr);
	column-gap: 16px;
	row-gap: 8px;
	margin: 0;
	max-width: unset;
}
.datePicker :global .react-datepicker__month-wrapper {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	column-gap: 16px;
	margin-bottom: 8px;
}
.datePicker :global .react-datepicker__year {
	margin: 0;
}
.datePicker :global .react-datepicker__day-name {
	font-size: 14px;
	line-height: 20px;
}
.datePicker :global .react-datepicker__day, .datePicker :global .react-datepicker__day-name {
	width: 32px;
	line-height: 32px;
	text-transform: capitalize;
}
.datePicker :global .react-datepicker__year-text, .datePicker :global .react-datepicker__month-text {
	text-transform: capitalize;
	margin: 0 auto;
	display: flex;
	height: 32px;
	width: 72px;
	justify-content: center;
	align-items: center;
}
.datePicker :global .react-datepicker__year-text--today, .datePicker :global .react-datepicker__month-text--today, .datePicker :global .react-datepicker__day--today {
	font-weight: normal;
}
.datePicker :global .react-datepicker__year-text--selected, .datePicker :global .react-datepicker__month--selected, .datePicker :global .react-datepicker__day--selected, .datePicker :global .react-datepicker__day--keyboard-selected, .datePicker :global .react-datepicker__month-text--keyboard-selected, .datePicker :global .react-datepicker__year-text--keyboard-selected {
	color: #fff;
	background: #1d2023;
	border-radius: 6px;
}
.datePicker :global .react-datepicker__year-text--selected:hover, .datePicker :global .react-datepicker__month--selected:hover, .datePicker :global .react-datepicker__day--selected:hover, .datePicker :global .react-datepicker__day--keyboard-selected:hover, .datePicker :global .react-datepicker__month-text--keyboard-selected:hover, .datePicker :global .react-datepicker__year-text--keyboard-selected:hover {
	background: #1d2023;
}
.datePicker :global .react-datepicker__year-text--selected:hover {
	background: #1d2023;
}
.datePicker :global .react-datepicker__day--outside-month {
	opacity: 0;
	pointer-events: none;
}
.datePicker :global .react-datepicker__header--custom {
	position: unset;
	max-width: 320px;
	margin: 0 auto;
	padding: 0 0 8px 0;
}
@media (min-width: 960px) {
	.datePicker :global .react-datepicker__header--custom {
		padding: 8px 0;
	}
}
.header {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	padding: 0;
	justify-content: space-between;
}
.header .controls {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	padding-bottom: 16px;
}
@media (min-width: 960px) {
	.header .controls {
		padding-bottom: 6px;
	}
}
.header .controls .type {
	gap: 8px;
	display: flex;
}
@media (min-width: 960px) {
	.header .controls .type {
		gap: 0;
	}
}
.headingSelector {
	cursor: pointer;
	border-radius: 6px;
	padding: 4px 16px 4px 6px;
	text-transform: capitalize;
	background: url("../../../../components_/Fields/DatePicker/icons/select-arrows.svg") no-repeat 95% 50%;
	background-size: 7px 14px;
	font-weight: 500;
}
@media (min-width: 960px) {
	.headingSelector {
		background: none;
	}
}
@media (min-width: 960px) {
	.headingSelector:hover {
		background-size: 7px 14px;
		background: rgba(188, 195, 208, 0.25) url("../../../../components_/Fields/DatePicker/icons/select-arrows.svg") no-repeat 95% 50%;
	}
}
.button {
	cursor: pointer;
	padding: 0;
	margin: 0;
	background: none;
	border: none;
}
.button:hover  {
	background: rgba(188, 195, 208, 0.25);
	border-radius: 6px;
}
.button.next {
	transform: rotate(180deg);
}
.buttonsWrapper {
	display: flex;
	position: absolute;
	bottom: 24px;
	right: 0;
	padding: 0 24px;
	width: 100%;
	justify-content: space-around;
}
.buttonsWrapper .mobileButton {
	max-width: 124px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 16px;
}
.buttonsWrapper .mobileButton:last-of-type {
	margin-right: 0;
}
@media (min-width: 960px) {
	.buttonsWrapper {
		display: none;
	}
}
.calendarIcon {
	cursor: pointer;
}
